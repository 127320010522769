port module Main exposing (main)

import Browser exposing (Document)
import Browser.Navigation as Nav
import ClarityQueue
import ClarityStep
import Counters
import FragmentAnalyzerPage
import Html exposing (a, div, img, text)
import Html.Attributes as Attr exposing (class, href, src, value)
import Html.Events exposing (onClick, onInput)
import Http
import Json.Decode as JD exposing (Decoder, string)
import Json.Encode as JE exposing (Value)
import Metrics.RawRunStorageStatus as RawRunStorageStatus
import ProjectGraph
import ProjectListPage
import ProjectOverviewPage
import ReleaseInfo
import RemoteData
import RunPage
import SharedState exposing (Config, ConfigPrecursor, fromConfigPrecursor)
import StatusPage
import StoredQueryPage
import TaskManagerAlternativePage
import TaskManagerPage
import Tat
import Url exposing (Url)
import Url.Builder
import Url.Parser as Parser exposing ((</>), (<?>), Parser, s, string)
import Url.Parser.Query as Query


type Page
    = TatPage Tat.Model
    | ProjectClarityPage ProjectGraph.Model
    | ProjectListPage ProjectListPage.Model
    | TaskManagerPage TaskManagerPage.Model
    | TaskManagerAlternativePage TaskManagerAlternativePage.Model
    | ProjectOverviewPage ProjectOverviewPage.Model
    | ReleasePage ReleaseInfo.Model
    | CounterPage Counters.Model
    | RunPage RunPage.Model
    | StatusPage StatusPage.Model
    | ClarityQueuePage ClarityQueue.Model
    | RawRunStorageStatus RawRunStorageStatus.Model
    | ClarityStepPage ClarityStep.Model
    | FragmentAnalyzerPage FragmentAnalyzerPage.Model
    | StoredQueryPage StoredQueryPage.Model
    | NotFound


type Route
    = TatRoute (Maybe String)
    | ProjectRoute Int
    | ProjectListRoute
    | ProjectSearchQuery String
    | CounterRoute Counters.CountersSubRoute
    | ReleasesRoute (Maybe Int)
    | ProjectOverviewRoute
    | TaskManagerRoute Int (Maybe String)
    | TaskManagerAlternativeRoute Int (Maybe String)
    | RunPageRoute Int
    | StatusRoute
    | ClarityQueueRoute Int
    | ClarityStepRoute
    | FragmentAnalyzerRoute
    | StoredQueryRoute
    | RawRunStorageStatusRoute


main : Program ConfigPrecursor Model Msg
main =
    Browser.application
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        }



-- TYPES


type alias Model =
    { userInfo : Maybe UserInfo
    , searchText : String
    , page : Page
    , message : String
    , token : String
    , config : Config
    , currentUrl : Url
    }


type alias UserInfo =
    { name : String }


userInfoDecoder : Decoder UserInfo
userInfoDecoder =
    JD.map UserInfo (JD.at [ "email" ] JD.string)


type Msg
    = UpdateStr String
    | SendToJs String
    | LoggedInFromGoogle String
    | BackendToken (Result Http.Error String)
    | GotUserInfo (Result Http.Error UserInfo)
    | GotProjectListPageMsg ProjectListPage.Msg
    | GotProjectOverviewMsg ProjectOverviewPage.Msg
    | GotTaskManagerMsg TaskManagerPage.Msg
    | GotTaskManagerAlternativeMsg TaskManagerAlternativePage.Msg
      --| GotProjectSummary (Maybe ProjectIdAndName)
    | DebugResponse (Result Http.Error String)
    | ClickedLink Browser.UrlRequest
    | ChangedUrl Url
    | GotTatMsg Tat.Msg
    | GotCounterMsg Counters.Msg
    | GotClarityProjectPageMsg ProjectGraph.Msg
    | GotClarityStepPageMsg ClarityStep.Msg
    | GotFragmentAnalyzerPageMsg FragmentAnalyzerPage.Msg
    | GotReleasePageMsg ReleaseInfo.Msg
    | DoSearch
    | SearchInput String
    | GotRunPageMsg RunPage.Msg
    | GotStatusPageMsg StatusPage.Msg
    | GotClarityQueuePageMsg ClarityQueue.Msg
    | GotRawRunStorageStatusMsg RawRunStorageStatus.Msg
    | GotStoredQueryPageMsg StoredQueryPage.Msg


parser : Parser (Route -> a) a
parser =
    Parser.oneOf
        [ Parser.map TatRoute (s "tat" </> Parser.fragment identity)
        , Parser.map CounterRoute <| Counters.parser "counters"
        , Parser.map ReleasesRoute (s "releases" </> Parser.fragment (Maybe.andThen String.toInt))
        , Parser.map RunPageRoute (s "runs" </> Parser.int)
        , Parser.map ClarityQueueRoute (s "clarity" </> s "queue" </> Parser.int)
        , Parser.map ProjectSearchQuery (s "project" <?> Query.map (Maybe.withDefault "") (Query.string "searchString"))
        , Parser.map ProjectListRoute (s "project")
        , Parser.map ProjectRoute (s "project" </> Parser.int)
        , Parser.map ProjectOverviewRoute (s "project-overview")
        , Parser.map TaskManagerRoute (s "task-manager" </> Parser.int </> Parser.fragment identity)
        , Parser.map TaskManagerAlternativeRoute (s "task-manager-alternative" </> Parser.int </> Parser.fragment identity)
        , Parser.map StatusRoute (s "status")
        , Parser.map RawRunStorageStatusRoute (s "raw")
        , Parser.map ClarityStepRoute (s "clarity" </> s "step")
        , Parser.map FragmentAnalyzerRoute (s "fragmentanalyzer")
        , Parser.map StoredQueryRoute (s "storedQueries")
        ]


urlToPage : Config -> Model -> Url -> ( Model, Cmd Msg )
urlToPage config model url =
    case Parser.parse parser url of
        Just (TatRoute selectedTest) ->
            let
                ( m, c ) =
                    Tat.init config selectedTest
            in
            ( { model | page = TatPage m }, Cmd.map GotTatMsg c )

        Just (ProjectRoute projectId) ->
            let
                ( m, c ) =
                    ProjectGraph.init config projectId
            in
            ( { model | page = ProjectClarityPage m }, Cmd.map GotClarityProjectPageMsg c )

        Just (ProjectSearchQuery str) ->
            let
                ( m, c ) =
                    ProjectListPage.init config str
            in
            ( { model | searchText = str, page = ProjectListPage m }, Cmd.map GotProjectListPageMsg c )

        Just ProjectListRoute ->
            let
                ( m, c ) =
                    ProjectListPage.init config ""
            in
            ( { model | page = ProjectListPage m }, Cmd.map GotProjectListPageMsg c )

        Just (CounterRoute subRoute) ->
            let
                ( m, c ) =
                    Counters.init "/counters/" config subRoute
            in
            ( { model | page = CounterPage m }, Cmd.map GotCounterMsg c )

        Just (ReleasesRoute suffix) ->
            let
                ( m, c ) =
                    ReleaseInfo.init config suffix
            in
            ( { model | page = ReleasePage m }, Cmd.map GotReleasePageMsg c )

        Just ProjectOverviewRoute ->
            let
                ( m, c ) =
                    ProjectOverviewPage.init config
            in
            ( { model | page = ProjectOverviewPage m }, Cmd.map GotProjectOverviewMsg c )

        Just (TaskManagerRoute graphRunId maybeFragment) ->
            let
                ( m, c ) =
                    TaskManagerPage.init config
                        (case model.page of
                            TaskManagerPage tm ->
                                Just tm

                            _ ->
                                Nothing
                        )
                        graphRunId
                        maybeFragment
            in
            ( { model | page = TaskManagerPage m }, Cmd.map GotTaskManagerMsg c )

        Just (TaskManagerAlternativeRoute graphRunId maybeFragment) ->
            let
                ( m, c ) =
                    TaskManagerAlternativePage.init config
                        (case model.page of
                            TaskManagerAlternativePage tm ->
                                Just tm

                            _ ->
                                Nothing
                        )
                        graphRunId
                        maybeFragment
            in
            ( { model | page = TaskManagerAlternativePage m }, Cmd.map GotTaskManagerAlternativeMsg c )

        Just RawRunStorageStatusRoute ->
            let
                ( m, c ) =
                    RawRunStorageStatus.init config
            in
            ( { model | page = RawRunStorageStatus m }, Cmd.map GotRawRunStorageStatusMsg c )

        Just StoredQueryRoute ->
            let
                ( m, c ) =
                    StoredQueryPage.init config
            in
            ( { model | page = StoredQueryPage m }, Cmd.map GotStoredQueryPageMsg c )

        Just StatusRoute ->
            let
                ( m, c ) =
                    StatusPage.init config
            in
            ( { model | page = StatusPage m }, Cmd.map GotStatusPageMsg c )

        Just (RunPageRoute id) ->
            let
                ( m, c ) =
                    RunPage.init config (Just id)
            in
            ( { model | page = RunPage m }, Cmd.map GotRunPageMsg c )

        Just (ClarityQueueRoute queueId) ->
            let
                ( m, c ) =
                    ClarityQueue.init config (Just queueId)
            in
            ( { model | page = ClarityQueuePage m }, Cmd.map GotClarityQueuePageMsg c )

        Just ClarityStepRoute ->
            let
                ( m, c ) =
                    ClarityStep.init config
            in
            ( { model | page = ClarityStepPage m }, Cmd.map GotClarityStepPageMsg c )

        Just FragmentAnalyzerRoute ->
            let
                ( m, c ) =
                    FragmentAnalyzerPage.init config
            in
            ( { model | page = FragmentAnalyzerPage m }, Cmd.map GotFragmentAnalyzerPageMsg c )

        _ ->
            ( { model | page = NotFound }, Cmd.none )



-- MODEL


init : ConfigPrecursor -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init configPrecursor url navKey =
    let
        model =
            { userInfo = Nothing, searchText = "", page = NotFound, message = "", token = "", config = config, currentUrl = url }

        config =
            fromConfigPrecursor configPrecursor navKey

        ( newModel, cmd ) =
            urlToPage config model url
    in
    ( newModel, Cmd.batch [ cmd, getUserInfo config ] )


getUserInfo config =
    Http.riskyRequest
        { method = "get"
        , headers = []
        , url = "https://services.genomicscore.be/user/info"
        , body = Http.stringBody "application/json" ""
        , expect = Http.expectJson GotUserInfo userInfoDecoder
        , timeout = Nothing
        , tracker = Nothing
        }



----- UPDATE


port toJs : String -> Cmd msg


port toElm : (Value -> msg) -> Sub msg



-- Necessary for websockets


port receiveSocketMsg : (JD.Value -> msg) -> Sub msg


port sendSocketCommand : JE.Value -> Cmd msg


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        GotProjectListPageMsg m ->
            case model.page of
                ProjectListPage p ->
                    let
                        ( newM, c ) =
                            ProjectListPage.update m p
                    in
                    ( { model | page = ProjectListPage newM }, Cmd.map GotProjectListPageMsg c )

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotProjectOverviewMsg m ->
            case model.page of
                ProjectOverviewPage p ->
                    let
                        ( newM, c ) =
                            ProjectOverviewPage.update m p
                    in
                    ( { model | page = ProjectOverviewPage newM }, Cmd.map GotProjectOverviewMsg c )

                _ ->
                    ( model, Cmd.none )

        GotUserInfo result ->
            case result of
                Ok userInfo ->
                    ( { model | userInfo = Just userInfo }, Cmd.none )

                Err _ ->
                    --(model,Cmd.none)
                    ( model, Nav.load <| Url.Builder.crossOrigin "https://login.genomicscore.be" [ "" ] [ Url.Builder.string "uri" <| Url.percentEncode <| Url.toString model.currentUrl ] )

        UpdateStr str ->
            ( { model | message = str }, Cmd.none )

        SendToJs str ->
            ( model, toJs str )

        DebugResponse _ ->
            ( model, Cmd.none )

        --GotProjectSummary result -> ({model | projectData = result }, Cmd.none)
        LoggedInFromGoogle token ->
            ( model
            , Cmd.batch
                [ Http.riskyRequest
                    { method = "post"
                    , headers = []
                    , url = model.config.serviceUrl ++ "/signin"
                    , body = Http.stringBody "application/json" token
                    , expect = Http.expectString BackendToken
                    , timeout = Nothing
                    , tracker = Nothing
                    }
                ]
            )

        BackendToken result ->
            case result of
                Ok str ->
                    ( { model | message = "Loggedin", token = str }, Cmd.none )

                Err _ ->
                    ( { model | message = "Oeps" ++ model.config.serviceUrl ++ " ???" }, Cmd.none )

        ClickedLink urlRequest ->
            case urlRequest of
                Browser.External href ->
                    ( model, Nav.load href )

                Browser.Internal url ->
                    ( model, Nav.pushUrl model.config.navKey (Url.toString url) )

        ChangedUrl url ->
            let
                ( page, cmd ) =
                    urlToPage model.config model url
            in
            ( page, cmd )

        GotTatMsg tmsg ->
            case model.page of
                TatPage tatModel ->
                    Tat.update tmsg tatModel |> updateWith (\subModel -> { model | message = "een update", page = TatPage subModel }) GotTatMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotClarityProjectPageMsg m ->
            case model.page of
                ProjectClarityPage cpModel ->
                    ProjectGraph.update m cpModel |> updateWith (\subModel -> { model | page = ProjectClarityPage subModel }) GotClarityProjectPageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotReleasePageMsg m ->
            case model.page of
                ReleasePage rpModel ->
                    ReleaseInfo.update m rpModel |> updateWith (\subModel -> { model | page = ReleasePage subModel }) GotReleasePageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotCounterMsg counterMsg ->
            case model.page of
                CounterPage counterModel ->
                    Counters.update counterMsg counterModel |> updateWith (\subModel -> { model | page = CounterPage subModel }) GotCounterMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        DoSearch ->
            let
                ( p, c ) =
                    ProjectListPage.init model.config model.searchText
            in
            ( { model | page = ProjectListPage p }, Cmd.batch [ Nav.pushUrl model.config.navKey <| "/project?searchString=" ++ model.searchText, Cmd.map GotProjectListPageMsg c, Cmd.map GotProjectListPageMsg <| ProjectListPage.makeRequest model.config model.searchText ] )

        SearchInput searchText ->
            ( { model | searchText = searchText }, Cmd.none )

        GotTaskManagerMsg tmMessage ->
            case model.page of
                TaskManagerPage tmModel ->
                    TaskManagerPage.update tmMessage tmModel |> updateWith (\subModel -> { model | page = TaskManagerPage subModel }) GotTaskManagerMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotTaskManagerAlternativeMsg tmMessage ->
            case model.page of
                TaskManagerAlternativePage tmModel ->
                    TaskManagerAlternativePage.update tmMessage tmModel |> updateWith (\subModel -> { model | page = TaskManagerAlternativePage subModel }) GotTaskManagerAlternativeMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotStoredQueryPageMsg sqm ->
            case model.page of
                StoredQueryPage sqModel ->
                    StoredQueryPage.update sqm sqModel |> updateWith (\subModel -> { model | page = StoredQueryPage subModel }) GotStoredQueryPageMsg

                _ ->
                    ( model, Cmd.none )

        GotRawRunStorageStatusMsg rawRunStorageStatusMsg ->
            case model.page of
                RawRunStorageStatus rawRunStorageStatusModel ->
                    RawRunStorageStatus.update rawRunStorageStatusMsg rawRunStorageStatusModel
                        |> updateWith (\subModel -> { model | page = RawRunStorageStatus subModel }) GotRawRunStorageStatusMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotStatusPageMsg statusPageMsg ->
            case model.page of
                StatusPage statusPageModel ->
                    StatusPage.update statusPageMsg statusPageModel
                        |> updateWith (\subModel -> { model | page = StatusPage subModel }) GotStatusPageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotRunPageMsg rpMsg ->
            case model.page of
                RunPage m ->
                    RunPage.update rpMsg m |> updateWith (\subModel -> { model | page = RunPage subModel }) GotRunPageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotClarityQueuePageMsg qpMsg ->
            case model.page of
                ClarityQueuePage m ->
                    ClarityQueue.update qpMsg m |> updateWith (\subModel -> { model | page = ClarityQueuePage subModel }) GotClarityQueuePageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotClarityStepPageMsg spMsg ->
            case model.page of
                ClarityStepPage m ->
                    ClarityStep.update spMsg m |> updateWith (\subModel -> { model | page = ClarityStepPage subModel }) GotClarityStepPageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )

        GotFragmentAnalyzerPageMsg fpMsg ->
            case model.page of
                FragmentAnalyzerPage m ->
                    FragmentAnalyzerPage.update fpMsg m |> updateWith (\subModel -> { model | page = FragmentAnalyzerPage subModel }) GotFragmentAnalyzerPageMsg

                _ ->
                    ( { model | message = "Message arrived to early" }, Cmd.none )


updateWith : (subModel -> Model) -> (subMsg -> Msg) -> ( subModel, Cmd subMsg ) -> ( Model, Cmd Msg )
updateWith toModel toMsg ( subModel, subCmd ) =
    ( toModel subModel
    , Cmd.map toMsg subCmd
    )



-- VIEW


view : Model -> Document Msg
view model =
    { title = "Genomics Core Lims"
    , body =
        [ div [ class "ui fixed  menu" ]
            [ div [ class "ui container" ]
                [ a [ href "#", class "header item" ]
                    [ img [ src "/images/logo.png " ] [], text "" ]
                , a [ href "/tat/", class "item" ] [ text "TAT" ]
                , a [ href "/project/", class "item" ] [ text "Project" ]
                , a [ href "/releases/", class "item" ] [ text "Release info" ]
                , a [ href "/counters/", class "item" ] [ text "Counters" ]
                , a [ href "/status/", class "item" ] [ text "Status" ]
                , a [ href "/csc/", class "item" ] [ text "CSC" ]
                , a [ href "/raw/", class "item" ] [ text "Raw" ]
                , Html.div [ class "right menu" ]
                    [ Html.div [ class "item" ]
                        [ Html.div [ class "ui action left icon input" ]
                            [ Html.i [ class "search icon" ] []
                            , Html.input [ class "", Attr.type_ "text", Attr.placeholder "search", value model.searchText, onInput SearchInput ] []
                            , Html.button [ class "ui button", onClick DoSearch ] [ text "Search" ]
                            ]
                        ]
                    ]
                ]
            ]
        , div [ class "ui main  container", Attr.style "margin-top" "5em" ]
            [ if model.userInfo == Nothing then
                text "Loading userinfo..."

              else
                case model.page of
                    NotFound ->
                        text "No page selected"

                    ReleasePage rpModel ->
                        Html.map GotReleasePageMsg (ReleaseInfo.view rpModel)

                    TatPage tatModel ->
                        Html.map GotTatMsg (Tat.view tatModel)

                    ProjectClarityPage projectClarityPageModel ->
                        Html.map GotClarityProjectPageMsg (ProjectGraph.view projectClarityPageModel)

                    CounterPage counterModel ->
                        Html.map GotCounterMsg (Counters.view counterModel)

                    ProjectListPage projectListPageModel ->
                        Html.map GotProjectListPageMsg (ProjectListPage.view projectListPageModel)

                    ProjectOverviewPage overviewModel ->
                        Html.map GotProjectOverviewMsg (ProjectOverviewPage.view overviewModel)

                    TaskManagerPage tmModel ->
                        Html.map GotTaskManagerMsg (TaskManagerPage.view tmModel)

                    TaskManagerAlternativePage tmModel ->
                        Html.map GotTaskManagerAlternativeMsg (TaskManagerAlternativePage.view tmModel)

                    RunPage rpModel ->
                        Html.map GotRunPageMsg (RunPage.view rpModel)

                    RawRunStorageStatus m ->
                        Html.map GotRawRunStorageStatusMsg (RawRunStorageStatus.view m)

                    StatusPage statusModel ->
                        Html.map GotStatusPageMsg (StatusPage.view statusModel)

                    StoredQueryPage sqPage ->
                        Html.map GotStoredQueryPageMsg (StoredQueryPage.view sqPage)

                    ClarityQueuePage qpModel ->
                        Html.map GotClarityQueuePageMsg (ClarityQueue.view qpModel)

                    ClarityStepPage spModel ->
                        Html.map GotClarityStepPageMsg (ClarityStep.view spModel)

                    FragmentAnalyzerPage fpModel ->
                        Html.map GotFragmentAnalyzerPageMsg (FragmentAnalyzerPage.view fpModel)
            ]
        ]
    }



-- SUBSCRIPTIONS


decodeValue : Value -> Msg
decodeValue x =
    let
        result =
            JD.decodeValue JD.string x
    in
    case result of
        Ok string ->
            LoggedInFromGoogle string

        Err _ ->
            UpdateStr "Silly JavaScript, you can't kill me!"


subscriptions : Model -> Sub Msg
subscriptions model =
    case model.page of
        CounterPage cm ->
            Counters.subscriptions cm |> Sub.map GotCounterMsg

        TaskManagerPage tm ->
            TaskManagerPage.subscriptions tm |> Sub.map GotTaskManagerMsg

        _ ->
            toElm decodeValue
